import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';

import * as styles from './404.module.css';

function NotFoundPage() {
  return (
    <Layout>
      <GatsbySeo noindex />
      <h1>404 Page Not Found</h1>
      <p>The page you requested does not exist.</p>
      <Link to="/" className={styles.button}>
        Continue shopping →
      </Link>
    </Layout>
  );
}

export default NotFoundPage;
